const defaultTheme = {
 //主题亮色
 "--theme-primary-suffix": "#fff",
 //默认白
 "--theme-primary-white": "rgba(0,0,0,1)",
 "--theme-primary": "transparent",
 "--theme-primary-hover": "rgba(0,0,0,1)",
 "--theme-primary-login-btn": "#12C16B",
 "--theme-primary-login-btn-color": "#fff",
 "--theme-primary-hover": "rgba(0,0,0,1)",
 "--theme-koutu-banner-2-title-color": "linear-gradient(45deg, #fff 0%, #12C16B 100%)",
 "--theme-koutu-banner-2-bg": "transparent",
 "--theme-primary-white": "#fff",
 //默认黑
 "--theme-primary-black": "#000",
 "--theme-primary-color": "#12C16B",

 "--theme-koutu-price-btn": "linear-gradient( 135deg, #25c174 0%, #12C16B 100%)",
 "--theme-koutu-price-color": "#12C16B",

 //主题背景颜色
 "--theme-primary": "linear-gradient(315deg, #36454F 0%, #5C6B77 100%)",
 // discuss-ppt 背景色
 "--theme-primary-ppt-wrap": "linear-gradient(315deg, #36454F 0%, #5C6B77 100%)",

 //价格 tag
 "--theme-primary-price-tag": "#3f4e59",
 "--theme-primary-price-tag-bg": "#e9edf8",
 //标签
 "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
 //tiny 类
 "--theme-primary-tiny": "#121212",
 //footer 背景色
 "--theme-primary-footer-bg": "#121212",
 //输入框聚焦
 "--theme-focus": "#419eff",
 //输入框错误
 "--theme-focus-err": "#ea3042",
 // 不透明度70%
 "--theme-primary-white-70": "#b2b2b2",

}



export const theme = {
 // 默认碳灰色
 defaultTheme,
}