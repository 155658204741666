<template>
  <div class="outWrap">
    <div class="price-container" id="price">
      <h1 class="title">{{ content.title }}</h1>
      <p class="des">{{ content.des }}</p>
      <div class="price-list">
        <template 
          v-for="(item, idx) in content.priceSetup">
        <div
          class="price-setup"
          v-if="productList[idx]"
          :key="idx"
        >
          <div class="tag">{{ productList[idx] && productList[idx].title
                  .replace(/{useTime1}/g, productList[idx].useTime1)
                  .replace(/{useTime}/g, productList[idx].useTime) }}</div>
          <div
            class="money"
            v-html="
              item.price
                .replace(/{money}/g, productList[idx].money)
                .replace(/{useTime}/g, productList[idx].useTime)
                  .replace(/{useTime1}/g, productList[idx].useTime1)
            "
          ></div>

          <ul>
            <li class="des" v-for="line in item.desList" :key="line">
              {{ line.replace(/{useTime}/g, productList[idx].useTime).
                replace(/{useTime1}/g, productList[idx].useTime1) }}
            </li>
          </ul>
          <button
            :style="`cursor: ${loading ? 'wait' : ''}`"
            v-show="productList.length"
            class="pay-button"
            @click="handlePay(idx)"
          >
            {{ item.buttonTxt }}
          </button>
          <p
            class="tip"
            v-if="item.tip"
            v-html="
              item.tip
                .replace(/{money}/g, productList[idx].money)
                .replace(/{price}/g, productList[idx].price)
                .replace(/{useTime}/g, productList[idx].useTime)
                .replace(/{useTime1}/g, productList[idx].useTime1)
                .replace(/{useTime2}/g, productList[idx].useTime2)
            "
          ></p>
        </div>

        </template>
      </div>
    </div>
  </div>
</template>
  
  <script>
const currencyMap = {
  USD: "$",
  PHP: "₱",
  ZAR: "R",
  GBP: "£",
  SGD: "S$",
  NZD: "NZ$",
  EUR: "€",
  INR: "₹",
  CAD: "C$",
  AUD: "AUD",
  IDR: "Rp",
  MYR: "RM",
  KRW: "₩",
  HKD: "HK$",
  TWD: "NT$",
  BRL: "R$",
  THB: "฿",
};

const offerTypePrices = {
  0: "price",
  1: "tryoutPrice",
  2: "firstDiscountPrice",
};

const durationUnitType = {
  1: "hour",
  2: "day",
  3: "week",
  4: "month",
  5: "year",
  100: "Lifetime",
};

//  填充价格套餐
const useFillPriceInfo = (item) => {
  item.money = item[offerTypePrices[item.offerType]];

  let unit = "durationUnit";
  let duration = "duration";
  if (item.offerType === 1) {
    unit = "tryoutDurationUnit";
    duration = "tryoutDuration";
  }
  const durationUnit = item[unit];
  const durationDate = item[duration];
  item.useTime1 = durationUnitType[durationUnit];
  item.useTime = durationUnitType[durationUnit];
  item.useTime2 = durationUnitType[item.durationUnit];
  if (durationDate === 100) {
    item.useTime = "LifeTime";
  } else {
    item.useTime = durationDate + "-" + item.useTime;
  }

  item.currencyUnit = currencyMap[item.currency];

  return item;
};

import { getProductList, createOrder, payOrder } from "@/api/pay";
import { $extractDomain } from "@/utils/index";
export default {
  name: "Koutu_Price",
  props: {
    payUrl: {
      type: Object,
      required: true,
      default: () => {},
    },
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
      productList: [
        {
          money: "19.99",
          price: "39.99",
          useTime: "1-Month",
        },
        {
          money: "99.99",
          price: "299.99",
          useTime: "1-Year",
        },
      ],
    };
  },
  created() {
    const mainPart = $extractDomain(window.location.hostname);
    getProductList({ code: mainPart, extend: {} }).then(result => {
    if (result.data && result.data.length) {
     this.productList = result.data.map(useFillPriceInfo)
    }
    })
  },
  mounted() {
    if (this.$route.hash) {
      this.$nextTick(() => {
        document.querySelector(this.$route.hash) &&
          document.querySelector(this.$route.hash).scrollIntoView();
      });
    }
  },
  methods: {
    async handlePay(idx) {
      if (!this.productList[idx].id) {
        return this.nopay();
      }
      if (this.loading) return;
      this.loading = true;
      try {
        const order = await createOrder({
          productsId: this.productList[idx].id,
          productsPriceId: "0",
          successUrl: `${this.$origin(
            this.payUrl.successUrl || "s/payCallBack"
          )}`, //  支付成功url
          cancelUrl: `${this.$origin(
            this.payUrl.cancelUrl || "s/payCallBack"
          )}`, //  取消支付链接
          userExtend: {},
          transparentExtend: {},
          orderAdInfoExtend: {},
          marketing: "seo",
          metadata:{}
        });
        window.location.href = order.data.redirectUrl
      } catch (e) {
        this.loading = false;
        alert(e.msg || e);
      }
    },
    nopay() {
      alert("Please wait patiently for us to access the payment");
    },
  },
};
</script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
  .outWrap {
    // background: url("@/assets/images/koutu/Price/bg.jpg") no-repeat top center;
    background-size: contain;
    .price-container {
      width: 1080px;
      margin: 0 auto 80px;
      // padding: 0 100px;
      padding-top:120px;
      text-align: center;
      font-family: Arial, Arial;
      .title {
        margin-bottom: 24px;
        font-size: 60px;
        font-weight: 900;
        color: var(--theme-primary-black);
        text-align: center;
      }

      .des {
        font-size: 16px;
        font-weight: 400;
        color: var(--theme-primary-black);
        line-height: 24px;
        margin-bottom: 44px;
      }

      .price-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        text-align: left;

        .price-setup {
          margin: 0 auto;
          // padding: 32px;
          box-sizing: border-box;
          background: white;
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
          border-radius: 36px;
          overflow: hidden;
          // &:hover {
          //   background: var(--theme-primary-suffix);
          //   .money,
          //   .tag {
          //     color: white;
          //   }
          //   ul {
          //     li {
          //       color: white;
          //       &::marker {
          //         color: #ffce9c;
          //       }
          //     }
          //   }
          //   .tip {
          //     background: #a783ff;
          //     color: rgba(255, 255, 255, 0.7);
          //   }
          // }
          .tag {
            padding: 40px 0;
            font-size: 30px;
            text-align: center;
            color: var(--theme-primary-black);
            border-bottom: 1px solid #e6e6e6;
          }
          .money {
            padding: 20px 0;
            font-weight: 900;
            font-size: 40px;
            color: var(--theme-primary-color);
            text-align: center;
          }
          ul {
            margin: 16px 0 24px;
            min-height: 280px;
            padding: 0 50px;

            li {
              font-size: 20px;
              color: var(--theme-primary-black);
              margin-bottom: 15px;
              &::marker {
                color: #d9d9d9;
              }
            }
          }

          .pay-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px 0;
            width: 80%;
            font-size: 26px;
            border: none;
            font-weight: bold;
            background: var(--theme-primary-color);
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2),
              inset 0px -3px 0px 0px var(--theme-primary-color);
            border-radius: 35px;
            color: var(--theme-primary-white);
            margin: 0 auto 40px;
            line-height: 24px;
            cursor: pointer;
            // &:hover {
            //   background: #D13200;
            // }
          }

          .tip {
            margin-top: 10px;
            font-size: 16px;
            color: #b2a9b2;
            padding: 25px 32px;
            background: #f1f1f1;
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .outWrap {
    .price-container {
      width: 100%;
      margin: 1rem auto;
      text-align: center;
      padding: 0.32rem;
      box-sizing: border-box;
      font-family: Arial, Arial;

      .title {
        margin-bottom: 24px;
        font-size: 36px;
        color: var(--theme-primary-black);
        line-height: 42px;
        text-align: center;
      }

      .des {
        margin-bottom: 44px;
        font-size: 16px;
        font-weight: 400;
        color: var(--theme-primary-black);
        line-height: 24px;
      }
      .price-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        text-align: left;

        .price-setup {
          min-width: 100%;
          margin: 0 auto;
          // padding: 32px;
          background: white;
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
          border-radius: 0.5rem;
          overflow: hidden;
          .tag {
            padding: 0.5rem 0;
            font-size: 0.4rem;
            text-align: center;
            color: var(--theme-primary-black);
            border-bottom: 1px solid #e6e6e6;
          }
          .money {
            padding: 0.5rem 0;
            font-weight: 900;
            font-size: 0.6rem;
            color: var(--theme-primary-color);
            text-align: center;
          }

          ul {
            margin: 0.5rem 0;
            padding: 0 0.6rem;

            li {
              font-size: 0.3rem;
              margin-bottom: 0.2rem;
              color: var(--theme-primary-black);
              &::marker {
                color: #d9d9d9;
              }
            }
          }

          .pay-button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.4rem;
            width: 70%;
            margin-bottom: 1rem;
            color: white;
            padding: 0.3rem 0;
            font-weight: bold;
            background: var(--theme-primary-color);
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2),
              inset 0px -3px 0px 0px var(--theme-primary-color);
            margin: 1rem auto 0.5rem;
            border-radius: 1rem;
            border: none;
            outline: none !important;
          }

          .tip {
            font-size: 0.3rem;
            color: #b2a9b2;
            padding: 0.5rem;
            background: #f1f1f1;
          }
        }
      }
    }
  }
}
</style>
  