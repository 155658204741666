<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <a :href="content.link">
      <img :src="$getFile(content.img)" v-if="content.img" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: "BannerImage",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    topEleBg() {
      return this.$store.state.topEleBg;
    },
  },
  methods: {
    swiperSwitch(idx) {
      this.$refs.swiper.swiper.slideTo(idx + 1);
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner-container {
    overflow: hidden;

    .banner-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      // flex-direction: column-reverse;
      width: 90%;
      max-width: 1580px;
      margin: 32px auto 0;
      padding: 22px 20px 24px;
      background: var(--theme-primary);
      border-radius: 16px;

      .banner-left {
        width: 627px;
        height: auto;
        cursor: default;

        .title {
          margin: -10px 0 4px;
          font-size: 46px;
          font-family: Poppins;
          color: var(--theme-primary-black);
          line-height: 52px;
        }

        .des {
          max-width: 626px;
          font-size: 18px;
          font-weight: bold;
          font-family: Poppins-Bold, Poppins;
          color: var(--theme-primary-black);
          line-height: 28px;

          .des1 {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            font-family: Poppins-Regular, Poppins;
            color: var(--theme-primary-black);
            line-height: 24px;
          }
        }
      }

      .banner-right {
        width: 464px;
        margin-right: 67px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .banner-container {
    .banner-box {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin: 0.32rem 0.32rem 0;
      padding: 0.78rem 0 0.81rem;
      background: var(--theme-primary);
      border-radius: 0.24rem;

      .banner-left {
        width: 6.86rem;
        margin: 0 auto;
        text-align: center;

        .title {
          margin: 0.3rem 0 0.2rem;
          padding: 0 0.2rem;
          font-size: 0.56rem;
          font-family: Poppins;
          color: var(--theme-primary-black);
          line-height: 0.72rem;
        }

        .des {
          max-width: 6.46rem;
          margin: 0 auto 0.2rem;
          font-size: 0.32rem;
          font-weight: bold;
          font-family: Poppins-Bold, Poppins;
          color: var(--theme-primary-black);
          line-height: 0.4rem;
        }

        .des1 {
          padding: 0 0.2rem;
          font-size: 0.28rem;
          font-weight: 400;
          font-family: Poppins-Regular, Poppins;
          color: var(--theme-primary-black);
          line-height: 0.4rem;
        }
      }

      .banner-right {
        width: 6.46rem;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
</style>