<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="banner-box">
      <h1 class="title">{{ content.title }}</h1>
      <p class="des">{{ content.des }}</p>
      <div class="input-box">
        <input class="input" type="text" :placeholder="content.placeholder" />
        <div class="btn" @click="goPath(content.link)">
          <img :src="$getFile(content.btnIcon)" v-if="content.btnIcon" alt="" />
          {{ content.btnText }}
        </div>
      </div>
    </div>
    <img class="bg" :src="$getFile(content.img)" v-if="content.img" alt="" />
  </div>
</template>
  
  <script>
export default {
  name: "Koutu_Banner_2",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods:{
    goPath(link){
      if(!link){
        // this.$message.error('We are trying to optimize the function.')
        document.querySelector(`#price`).scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        return;
      }
      window.location.href = link;
    }
  }
};
</script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
  .banner-container {
    width: 100%;
    height: 745px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .banner-box {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 956px;
      margin: auto;
      .title {
        font-family: Arial, Arial;
        font-weight: bold;
        font-size: 70px;
        color: var(--theme-primary-white);
      }
      .des {
        font-family: Arial, Arial;
        font-weight: 400;
        font-size: 36px;
        color: var(--theme-primary-white-70);
        line-height: 46px;
        margin-top: 20px;
      }
      .input-box {
        margin-top: 60px;
        width: 100%;
        height: 90px;
        border-radius: 50px;
        padding: 10px;
        // box-shadow: 0px 25px 50px 0px var(--theme-koutu-price-btn-shadow);
        display: flex;
        border: 1px solid var(--theme-koutu-price-color);
        justify-content: space-between;
        align-items: center;
        .input {
          background: transparent;
          font-size: 20px;
          flex: 1;
          width: 100%;
          height: 100%;
          border: none !important;
          margin: 0 30px;
          color: #fff;
        }
        .btn {
          width: 168px;
          height: 60px;
          flex: 0 0 168px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Arial, Arial;
          font-weight: bold;
          font-size: 20px;
          color: #ffffff;
          line-height: 72px;
          border-radius: 30px;
          cursor: pointer;
          background: var(--theme-koutu-price-btn);
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
          img {
            width: 24px;
            margin-right: 10px;
          }
        }
      }
    }
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .banner-container {
    width: 100%;
    height: 8rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .banner-box {
      position: relative;
      width: 90%;
      height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      margin: auto;
      .title {
        font-family: Arial, Arial;
        font-weight: bold;
        font-size: 0.5rem;
        color: var(--theme-primary-white);
      }
      .des {
        font-family: Arial, Arial;
        font-weight: 400;
        font-size: 0.3rem;
        color: var(--theme-primary-white-70);
        margin-top: 0.2rem;
      }
      .input-box {
        margin-top: 0.6rem;
        width: 100%;
        height: 0.9rem;
        border-radius: 0.5rem;
        padding: 0.1rem;
        // box-shadow: 0px 25px 50px 0px var(--theme-koutu-price-btn-shadow);
        display: flex;
        border: 1px solid var(--theme-koutu-price-color);
        justify-content: space-between;
        align-items: center;
        .input {
          background: transparent;
          font-size: 0.3rem;
          flex: 1;
          width: 100%;
          height: 100%;
          border: none !important;
          margin: 0 0.3rem;
          color: #fff;
          &::placeholder {
            color: #dedede;
          }
        }
        .btn {
          width: 2rem;
          height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Arial, Arial;
          font-weight: bold;
          font-size: 0.2rem;
          color: #ffffff;
          border-radius: 0.3rem;
          cursor: pointer;
          background: var(--theme-koutu-price-btn);
          img {
            width: 0.24rem;
            margin-right: 0.2rem;
          }
        }
      }
    }
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
</style>