<template>
  <div>
    <div class="maks" v-loading="loading" v-if="loading"></div>
    <div
      v-for="(component, name) in components"
      :key="name"
      class="layout-items"
    >
      <component :is="component.component" v-bind="component.props"></component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      components: {},
      loading: true,
    };
  },
  methods: {},
  async created() {
    try {
      const $id = this.$route.params.id;
      // if (host == 'localhost') host = sessionStorage.getItem('SiteEntrance')
      // let result = await this.$api.getPageInfo({ host, page: 'index' })
      // const componentConfig = result.data.content
      // console.log(result,"here result>>>")
      const response = await import(`../site-json/template/${$id}.json`);
      const componentConfig = await this.$replacePlaceholders(response.default);
      this.loading = false;
      for (const [name, config] of Object.entries(componentConfig)) {
        this.$set(this.components, name, {
          component: name,
          props: config.props,
        });
      }
    } catch (error) {
      console.error("Failed to load component configuration:", error);
    }
  },
};
</script>
<style lang="scss">
.layout-items {
  // margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
