<template>
    <!-- 首页banner 组件 -->
    <div class="banner-container">
      <div class="banner-box">
        <div class="banner-left">
          <h1 class="title">{{ content.title }}</h1>
          <p class="des">{{ content.des }}</p>
          <a :href="content.link"> 
            <div class="btn">{{ content.btnText }}</div>
          </a>
        </div>
        <div class="banner-right">
          <img :src="$getFile(content.img)" v-if="content.img" alt="">
 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_Banner_2',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      background: var(--theme-koutu-banner-2-bg);
      .banner-box {
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: 1080px;
        margin: 0 auto;
        padding: 50px 0;
  
        .banner-left {
          width: 60%;
          height: auto;
          cursor: default;
  
          .title {
            margin-bottom: 40px;
            font-size: 70px;
            font-weight: 900;
            line-height: 1.1;
            background-image: var(--theme-koutu-banner-2-title-color);
            -webkit-background-clip: text;
            color: transparent;
          }
          .des {
            font-size: 24px;
            color: var(--theme-koutu-banner-2-color);
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--theme-koutu-banner-2-btn);
            font-size: 20px;
            font-weight: bold;
            margin-top: 40px;
            background: var(--theme-koutu-banner-2-btn-bg);
            border-radius: 6px;
            cursor: pointer;
          }
        }
  
        .banner-right {
          width: 40%;
          margin-right: 10px;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      font-family: Arial, Arial;
      width: 100%;
      background: var(--theme-koutu-banner-2-bg);
      .banner-box {
        display: flex;
        flex-wrap: wrap;
        padding: .5rem 0 1rem;
        .banner-left {
          padding: 0 .5rem;
          margin: 0 auto;
          text-align: center;
  
          .title {
            margin: 0 0 .2rem;
            padding: 0 .2rem;
            font-size: 0.56rem;
            color: var(--theme-koutu-banner-2-color);
            line-height: .72rem;
          }
  
          .des {
            margin: .4rem auto;
            font-size: .32rem;
            color: var(--theme-koutu-banner-2-color);
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--theme-koutu-banner-2-btn);
            font-size: 20px;
            font-weight: bold;
            background: var(--theme-koutu-banner-2-btn-bg);
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
            border-radius: 30px;
            margin: .5rem auto;
            cursor: pointer;
          }

        }
  
        .banner-right {
          width: 100%;
          margin: 0 auto;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  </style>