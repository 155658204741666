<template>
  <!-- 底部导航栏组件 -->
  <div class="footer-container">
    <div class="footer-box">
      <div class="top-container">
        <div class="main-container">
          <div class="footer-top">
              <img :src="$getFile(content.logo)" class="logo" v-if="content.logo" alt="">
            <div class="logo" v-else>{{ content.title }}</div>
            <div class="intro">{{ content.des }}</div>
          </div>
          <p class="introH5">{{ content.des }}</p>
          <div class="footer-list">
            <div v-for="(item, idx) in fastList" :key="idx" 
            v-show="!item.notShow"
             class="footer-item">
              <div class="item-title">{{ item.title }}</div>
              <p v-for="(target, tIdx) in item.list" :key="tIdx">
                <template v-if="target.type !== undefined">
                  <a :href="target.name">{{ target.title }}</a>
                </template>
                <template v-else>
                  <a :rel="target.rel || ''" :href="target.link">{{ target.title }}</a>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-container">
        <div class="bottom-box row-between">
          <div class="business-info">
            <div> {{ content.bottomMobile }}</div>
          </div>
        </div>
      </div>
      <div class="bottom-mobile">
        <div>{{ content.bottomMobile }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBar',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      direction: 'down'
    }
  },
  computed: {
    fastList() {
      return this.content.list || []
    }
  },
  mounted() {
  },
  methods: {
    handleMenu(flag) {
      this.direction = flag ? 'up' : 'down'
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  // text-transform: uppercase;
  color: var(--theme-primary-white);
}

@media #{'only screen and (min-width: 992px)'} {
  .footer-container {
    font-family: Arial, Arial;
    .footer-box {
      .top-container {
        width: auto;
        padding-top: 60px;
        background: var(--theme-primary-footer-bg);

        .main-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 1080px;
          margin: 0 auto;
          padding-bottom: 60px;
          box-sizing: border-box;
          border-bottom: 1px solid #ffffff33 ;

          .footer-top {
            // width: 300px;
            border-right: 1px solid #ffffff33;

            img {
              width: 179px;
            }

            .intro {
              width: 280px;
              margin-top: 17px;
              margin-right: 60px;
              font-size: 16px;
              font-weight: 400;
              color: var(--theme-primary-white-70);
              line-height: 1.4;
            }
          }

          .footer-list {
            display: flex;
            gap: 100px;

            .footer-item {
              text-align: center;
              // width: 160px;

              .item-title {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 600;
                color: var(--theme-primary-white);
              }

              p {
                font-size: 16px;
                font-weight: 400;
                word-wrap: break-word;
                line-height: 22px;
                cursor: pointer;

                &:not(:last-child) {
                  margin-bottom: 16px;
                }

                a {
                  color: var(--theme-primary-white-70);
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }

        .introH5 {
          display: none;
        }
      }

      .bottom-container {
        width: auto;
        padding: 20px 0;
        background: var(--theme-primary-footer-bg);
        color: #ffffff33;

        .bottom-box {
          width: 1180px;
          margin: 0 auto;
          text-align: center;

          .business-info {
            position: relative;
            font-size: 14px;
            text-align: center;
            color: var(--theme-primary-white-70);

            img {
              position: absolute;
              right: 0;
              top: -4px;
              width: 300px;
            }
          }
        }
      }

      .bottom-mobile {
        display: none;
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .logo {
    text-align: center;
  }

  .footer-container {
    font-family: Arial, Arial;
    .footer-box {
      width: auto;
      background: var(--theme-primary-footer-bg);
      color: var(--theme-primary-white);

      .main-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding-top: 1rem;
        box-sizing: border-box;
        border-bottom: #ffffff33 1px solid;

        .footer-top {
          img {
            display: none;
          }

          .intro {
            display: none;
          }
        }

        .introH5 {
          height: 1.76rem;
          margin-top: .4rem;
          font-size: 0.28rem;
          padding: .2rem;
          font-weight: 400;
          color: var(--theme-primary-white);
          line-height: .44rem;
          text-align: center;
        }

        .footer-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: .78rem .8rem;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: .8rem 0;
          box-sizing: border-box;

          .footer-item {
            width: 2.88rem;

            .item-title {
              margin-bottom: .3rem;
              font-size: .32rem;
              color: var(--theme-primary-white);
              line-height: .53rem;
            }

            p {
              font-size: .3rem;
              font-weight: 400;
              line-height: .46rem;
              cursor: pointer;
              word-wrap: break-word;

              &:not(:last-child) {
                margin-bottom: .32rem;
              }

              a {
                color: var(--theme-primary-white-70);
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .bottom-container {
        display: none;
      }

      .bottom-mobile {
        margin: 0 .32rem;
        padding: .41rem 0 .3rem;
        font-size: .24rem;
        color: var(--theme-primary-white);
        align-items: center;
        line-height: .35rem;
        text-align: center;

        img {
          width: 3rem;
        }
      }
    }
  }
}
</style>
