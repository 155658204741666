var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outWrap"},[_c('div',{staticClass:"price-container",attrs:{"id":"price"}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.content.title))]),_c('p',{staticClass:"des"},[_vm._v(_vm._s(_vm.content.des))]),_c('div',{staticClass:"price-list"},[_vm._l((_vm.content.priceSetup),function(item,idx){return [(_vm.productList[idx])?_c('div',{key:idx,staticClass:"price-setup"},[_c('div',{staticClass:"tag"},[_vm._v(_vm._s(_vm.productList[idx] && _vm.productList[idx].title .replace(/{useTime1}/g, _vm.productList[idx].useTime1) .replace(/{useTime}/g, _vm.productList[idx].useTime)))]),_c('div',{staticClass:"money",domProps:{"innerHTML":_vm._s(
            item.price
              .replace(/{money}/g, _vm.productList[idx].money)
              .replace(/{useTime}/g, _vm.productList[idx].useTime)
                .replace(/{useTime1}/g, _vm.productList[idx].useTime1)
          )}}),_c('ul',_vm._l((item.desList),function(line){return _c('li',{key:line,staticClass:"des"},[_vm._v(" "+_vm._s(line.replace(/{useTime}/g, _vm.productList[idx].useTime). replace(/{useTime1}/g, _vm.productList[idx].useTime1))+" ")])}),0),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.productList.length),expression:"productList.length"}],staticClass:"pay-button",style:(`cursor: ${_vm.loading ? 'wait' : ''}`),on:{"click":function($event){return _vm.handlePay(idx)}}},[_vm._v(" "+_vm._s(item.buttonTxt)+" ")]),(item.tip)?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(
            item.tip
              .replace(/{money}/g, _vm.productList[idx].money)
              .replace(/{price}/g, _vm.productList[idx].price)
              .replace(/{useTime}/g, _vm.productList[idx].useTime)
              .replace(/{useTime1}/g, _vm.productList[idx].useTime1)
              .replace(/{useTime2}/g, _vm.productList[idx].useTime2)
          )}}):_vm._e()]):_vm._e()]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }